import React  from "react";
import Layout from "../../components/layout/Layout";
import ForgotPasswordContent from "../../components/ForgotPasswordContent";
import { Script } from "gatsby";
import Clickmagic from "../../components/common/ClickMagic/Clickmagic";

const ForgotPassword = () => {

    return (
		<Layout>
			<ForgotPasswordContent/>
		</Layout>
	);

};

export const Head = (props: any) => {
	return (
		<>
			<title>Forgot Password</title>
			<meta name="robots" content="index, follow"></meta>
			<link rel="canonical" href="https://spartanpeptides.com/forgot-password" data-gatsby-head="true"></link>
		</>
	);
};

export default ForgotPassword;